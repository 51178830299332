<script>
  import axios from "axios";

  let name = "";
  let email = "";
  let phoneNumber = "";
  let enquiry = "";
  let error = null;
  let submitted = false;

  function isValid() {
    error = null;

    if (name.length <= 0) {
      error = "Please enter a name";
      return false;
    }

    if (email.length <= 0) {
      error = "Please enter an email";
      return false;
    }

    return true;
  }

  async function submit() {
    if (isValid()) {
      submitted = true;

      await axios.post(
        "https://environmental-systems-web-service.fly.dev/contact/",
        {
          name,
          email,
          phone_number: phoneNumber,
          enquiry,
        }
      );
    }
  }
</script>

<main>
  <div>
    <p>Phone: (03) 5482 5882</p>
  </div>

  <form>
    <input bind:value={name} placeholder="Your Name (required)" />
    <input bind:value={email} placeholder="Your Email (required)" />
    <input bind:value={phoneNumber} placeholder="Phone Number" />
    <textarea bind:value={enquiry} maxlength="512" placeholder="Enquiry" />
  </form>

  {#if error}
    <p class="error">{error}</p>
  {/if}

  {#if submitted}
    <p>Thanks for contacting us!</p>
  {:else}
    <button on:click={submit}>Submit</button>
  {/if}
</main>

<style>
  main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  form {
    width: 400px;
    max-width: 100%;
  }

  .error {
    color: #b91c1c;
  }

  input,
  textarea {
    width: 100%;
    font-size: 16px;
    border: solid 1px #aaaaaa;
    border-radius: 5px;
    padding: 6px;
    margin-bottom: 12px;
    resize: vertical;
  }

  button {
    font-family: "FiraSans", sans-serif;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.4s;
  }

  button:hover {
    opacity: 0.7;
  }

  button:active {
    background-color: #002fff;
  }
</style>
