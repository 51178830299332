<script>
  import { Router, Link, Route } from "svelte-navigator";
  import Header from "./Header.svelte";
  import Home from "./Home.svelte";
  import Contact from "./Contact.svelte";
</script>

<Router primary={false}>
  <main>
    <Header />
    <div class="body">
      <Route path="/">
        <Home />
      </Route>

      <Route path="contact">
        <Contact />
      </Route>
    </div>
  </main>
</Router>

<style>
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .body {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: left;
  }
</style>
