<main>
  <div class="content">
    <p class="reconstruct-banner">Under reconstruction...</p>
    <div class="two-col">
      <div>
        <p>
          Advanced Environmental Systems Pty Ltd is a dynamic and innovative
          organisation that has been supplying environmental management,
          planning and consulting services since 1993. The business is focused
          on providing the best professional practice and environmental
          management expertise to the community, industry and government. AES
          has an established team of professionals, capable of providing
          specialised advice and support in an array of environmental
          disciplines.
        </p>
        <ul>
          <li>Energy audits, assessments and action plans</li>
          <li>Water analysis and management</li>
          <li>Carbon accounting</li>
          <li>Bushfire hazard assessment</li>
          <li>Environmental impact studies</li>
          <li>Flora and fauna surveys</li>
          <li>Aboriginal cultural heritage</li>
          <li>Soil surveys, mapping and land capability assessment</li>
          <li>Wastewater management and irrigation scheduling</li>
          <li>Rural enterprise options</li>
          <li>Salinity assessment and remediation</li>
        </ul>
      </div>
      <img src="images/greenhouse.webp" alt="Greenhouse" />
    </div>
  </div>
</main>

<style>
  .content {
    padding: 32px 24px;
  }

  .reconstruct-banner {
    text-align: center;
    font-size: 12px;
    color: #aaaaaa;
  }

  .two-col {
    display: flex;
    align-items: center;
  }

  img {
    max-width: 50%;
    margin: 0px 48px;
    border-radius: 8px;
  }

  @media only screen and (max-width: 900px) {
    img {
      display: none;
    }

    .content {
      padding: 0px 24px;
    }
  }
</style>
