<script>
  import { Link } from "svelte-navigator";
</script>

<main>
  <div class="row">
    <a href="/">
      <img src="images/aes-logo.webp" alt="AES Logo" class="logo" />
    </a>
    <div class="spacer" />
    <Link to="contact">
      <button>Contact Us</button>
    </Link>
  </div>
</main>

<style>
  main {
    width: 100%;
    max-width: 1200px;
    font-size: 18px;
  }

  a {
    width: 240px;
    max-width: 55%;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 16px 24px;
  }

  .logo {
    cursor: pointer;
    width: 100%;
    min-width: 190px;
  }

  .spacer {
    flex-grow: 1;
  }

  button {
    font-family: "FiraSans", sans-serif;
    font-size: 16px;
    color: white;
    background-color: #8db63f;
    border: none;
    padding: 12px 24px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.4s;
  }

  button:hover {
    opacity: 0.6;
  }

  @media only screen and (max-width: 400px) {
    .row {
      flex-direction: column;
      justify-content: center;
    }

    button {
      margin-top: 24px;
    }
  }
</style>
